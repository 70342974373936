export const cdmx = {
  center: [19.39068, -99.2836968],
  geojson: {
    type: "FeatureCollection",
    name: "Propuesta SixFlags - Nicolás Romero",
    crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    features: [
      {
        type: "Feature",
        properties: { Name: "Polygon 2", description: null },
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-99.2791884, 19.5924653],
              [-99.2779542, 19.591779],
              [-99.2758619, 19.5894854],
              [-99.2753508, 19.5880334],
              [-99.274948, 19.5866147],
              [-99.2721341, 19.5836462],
              [-99.2714299, 19.5827078],
              [-99.2693827, 19.5815108],
              [-99.2691379, 19.581082],
              [-99.2694666, 19.5795916],
              [-99.2697515, 19.5778588],
              [-99.2697544, 19.572735],
              [-99.2699424, 19.5707945],
              [-99.2704525, 19.5691171],
              [-99.2718439, 19.566722],
              [-99.277117, 19.5583795],
              [-99.2776477, 19.5566113],
              [-99.2776719, 19.5550199],
              [-99.2773947, 19.5517705],
              [-99.2769462, 19.5472272],
              [-99.2771367, 19.5456564],
              [-99.2780564, 19.5423053],
              [-99.2792509, 19.5372426],
              [-99.2805178, 19.5321445],
              [-99.2811512, 19.5294448],
              [-99.2818698, 19.5271683],
              [-99.2835069, 19.5244725],
              [-99.2855297, 19.5223033],
              [-99.2885465, 19.5194798],
              [-99.2904161, 19.5175433],
              [-99.2918571, 19.51595],
              [-99.2932254, 19.5141906],
              [-99.295275, 19.510882],
              [-99.2976092, 19.5070588],
              [-99.2993749, 19.5051369],
              [-99.3050518, 19.5006755],
              [-99.3063824, 19.4996379],
              [-99.3070807, 19.4987721],
              [-99.3076709, 19.496916],
              [-99.3089155, 19.491429],
              [-99.3101285, 19.4885544],
              [-99.3121536, 19.4842244],
              [-99.314007, 19.4802079],
              [-99.3142833, 19.4788625],
              [-99.3142378, 19.4773351],
              [-99.3138858, 19.4762544],
              [-99.3133842, 19.474891],
              [-99.3123166, 19.4721841],
              [-99.3106417, 19.4677309],
              [-99.3099796, 19.4655218],
              [-99.3094891, 19.4633126],
              [-99.3084654, 19.4580447],
              [-99.3080677, 19.456726],
              [-99.3074555, 19.4555084],
              [-99.3045149, 19.4518054],
              [-99.3016062, 19.4482372],
              [-99.2991291, 19.4452125],
              [-99.2984716, 19.4439212],
              [-99.2976294, 19.4411279],
              [-99.2972065, 19.4386887],
              [-99.2973189, 19.4335581],
              [-99.2965806, 19.4311951],
              [-99.2953439, 19.4282645],
              [-99.2950166, 19.4265638],
              [-99.2953388, 19.4255039],
              [-99.2959078, 19.4245047],
              [-99.2978984, 19.4226903],
              [-99.2996316, 19.4207141],
              [-99.300227, 19.4190869],
              [-99.3000739, 19.4180611],
              [-99.29917, 19.4159091],
              [-99.2962065, 19.4093909],
              [-99.2959725, 19.4071308],
              [-99.2972752, 19.4024676],
              [-99.2971825, 19.4005741],
              [-99.2967036, 19.398721],
              [-99.2961165, 19.3949561],
              [-99.2955716, 19.3905439],
              [-99.2953709, 19.388878],
              [-99.2955402, 19.3870294],
              [-99.2970022, 19.3814509],
              [-99.2982602, 19.3790847],
              [-99.2988637, 19.3779026],
              [-99.2990309, 19.3764532],
              [-99.2988249, 19.3754174],
              [-99.2986031, 19.3743787],
              [-99.2985471, 19.3739067],
              [-99.2985062, 19.3734171],
              [-99.2984822, 19.3728615],
              [-99.2987586, 19.3715496],
              [-99.2991149, 19.3707078],
              [-99.2996585, 19.3698319],
              [-99.3003742, 19.3689857],
              [-99.3031953, 19.3659612],
              [-99.303995, 19.3647447],
              [-99.3043388, 19.3637163],
              [-99.3036649, 19.3584372],
              [-99.3038434, 19.3571218],
              [-99.3050518, 19.3543082],
              [-99.3059991, 19.3519509],
              [-99.3064744, 19.3497961],
              [-99.307335, 19.3462102],
              [-99.3078271, 19.344641],
              [-99.3081375, 19.3424088],
              [-99.3085982, 19.3418773],
              [-99.3095784, 19.3414745],
              [-99.3104084, 19.3408491],
              [-99.3110151, 19.3398917],
              [-99.3112249, 19.3384687],
              [-99.3118314, 19.3364657],
              [-99.3128669, 19.3346044],
              [-99.3104999, 19.3351999],
              [-99.3074936, 19.3347194],
              [-99.3045581, 19.3343445],
              [-99.303077, 19.3347084],
              [-99.2986443, 19.3370016],
              [-99.2975921, 19.3388804],
              [-99.296121, 19.3403184],
              [-99.291882, 19.3437814],
              [-99.2901061, 19.3443084],
              [-99.2884481, 19.3450674],
              [-99.2855852, 19.3477125],
              [-99.2821064, 19.3481455],
              [-99.2838469, 19.3469351],
              [-99.2848329, 19.3461822],
              [-99.285218, 19.3449029],
              [-99.286483, 19.3432794],
              [-99.2862459, 19.3423848],
              [-99.2838667, 19.3435295],
              [-99.2832469, 19.3443503],
              [-99.2804196, 19.3443317],
              [-99.2800775, 19.3438866],
              [-99.2801217, 19.3431986],
              [-99.2815834, 19.3422276],
              [-99.2814572, 19.3405682],
              [-99.2809877, 19.3391517],
              [-99.2811537, 19.3370982],
              [-99.2800136, 19.3361727],
              [-99.280161, 19.3352067],
              [-99.2816173, 19.3353543],
              [-99.2832896, 19.3363393],
              [-99.2842323, 19.3365953],
              [-99.285085, 19.336364],
              [-99.2875301, 19.3309543],
              [-99.2874601, 19.3305813],
              [-99.2871969, 19.3301882],
              [-99.286499, 19.3296042],
              [-99.2855886, 19.3298373],
              [-99.2846354, 19.3286529],
              [-99.2824071, 19.328309],
              [-99.2809966, 19.3289051],
              [-99.2806376, 19.328833],
              [-99.2803108, 19.3284876],
              [-99.2804132, 19.3277574],
              [-99.2806496, 19.3270273],
              [-99.2811542, 19.326449],
              [-99.2829254, 19.325505],
              [-99.2827248, 19.3250902],
              [-99.28039, 19.3257755],
              [-99.279246, 19.3272504],
              [-99.2789047, 19.3270211],
              [-99.2792276, 19.3260458],
              [-99.2792817, 19.3256594],
              [-99.2791642, 19.3252528],
              [-99.2786103, 19.325118],
              [-99.278271, 19.3254084],
              [-99.2776293, 19.3264243],
              [-99.2769017, 19.3273086],
              [-99.2748605, 19.32794],
              [-99.2741832, 19.3278608],
              [-99.2735917, 19.3275994],
              [-99.2729306, 19.3266193],
              [-99.2714754, 19.325882],
              [-99.2708786, 19.3263394],
              [-99.2695952, 19.3265134],
              [-99.2692485, 19.3270711],
              [-99.2690949, 19.3276694],
              [-99.2685303, 19.3279547],
              [-99.2676076, 19.328097],
              [-99.2668351, 19.3278141],
              [-99.2652687, 19.326985],
              [-99.2644533, 19.3265854],
              [-99.2640993, 19.3261351],
              [-99.2643621, 19.3244953],
              [-99.2641958, 19.3240906],
              [-99.2629084, 19.32314],
              [-99.2631983, 19.3210465],
              [-99.2628282, 19.319777],
              [-99.2630938, 19.3176438],
              [-99.2628913, 19.3167873],
              [-99.262672, 19.3166679],
              [-99.2624765, 19.3164664],
              [-99.2627102, 19.3149588],
              [-99.2624918, 19.3146862],
              [-99.2621018, 19.313077],
              [-99.2625808, 19.3111844],
              [-99.2618077, 19.3109379],
              [-99.2616194, 19.310808],
              [-99.2617008, 19.3107028],
              [-99.2615548, 19.3106132],
              [-99.2609904, 19.3105237],
              [-99.2606868, 19.3102903],
              [-99.2606269, 19.310113],
              [-99.2610285, 19.3089231],
              [-99.2610161, 19.3083356],
              [-99.2601728, 19.3086283],
              [-99.2615671, 19.3061069],
              [-99.2614181, 19.3058442],
              [-99.2611145, 19.3056937],
              [-99.2596629, 19.3057659],
              [-99.2587446, 19.305485],
              [-99.2582919, 19.305055],
              [-99.2576931, 19.3043186],
              [-99.2568689, 19.3039771],
              [-99.2561843, 19.303985],
              [-99.2538598, 19.304561],
              [-99.2523327, 19.3041453],
              [-99.2515423, 19.3038767],
              [-99.250988, 19.3034461],
              [-99.2488757, 19.3036855],
              [-99.2487966, 19.3026795],
              [-99.2474434, 19.3031671],
              [-99.2457421, 19.3036235],
              [-99.2440838, 19.3042216],
              [-99.2451325, 19.3032576],
              [-99.2458057, 19.3021923],
              [-99.2455782, 19.3020113],
              [-99.2434732, 19.3029035],
              [-99.2433514, 19.3026712],
              [-99.2432725, 19.3016592],
              [-99.2420958, 19.3018063],
              [-99.24108, 19.3018016],
              [-99.2410334, 19.30163],
              [-99.2405253, 19.3010256],
              [-99.2405422, 19.3001614],
              [-99.240028, 19.2998237],
              [-99.2398572, 19.2994911],
              [-99.2390207, 19.2989552],
              [-99.2387744, 19.2986117],
              [-99.2386472, 19.2982503],
              [-99.2383136, 19.2973143],
              [-99.2383721, 19.2966032],
              [-99.2382374, 19.2959124],
              [-99.238666, 19.2950191],
              [-99.2395238, 19.294551],
              [-99.2401804, 19.2942881],
              [-99.2413842, 19.2942479],
              [-99.2428079, 19.294121],
              [-99.2428888, 19.2939792],
              [-99.2422832, 19.292845],
              [-99.240514, 19.2927031],
              [-99.2402823, 19.2890923],
              [-99.2400162, 19.2856008],
              [-99.239836, 19.2854815],
              [-99.2399422, 19.2853138],
              [-99.2399734, 19.2849435],
              [-99.2392954, 19.2777826],
              [-99.2389179, 19.2763334],
              [-99.2395317, 19.2762094],
              [-99.2403333, 19.276207],
              [-99.2408747, 19.2761514],
              [-99.2417487, 19.2759996],
              [-99.2417753, 19.2756401],
              [-99.2420568, 19.2753616],
              [-99.2421989, 19.2751237],
              [-99.2423087, 19.2747832],
              [-99.242236, 19.2744022],
              [-99.2417475, 19.2730604],
              [-99.2418597, 19.2729339],
              [-99.2417066, 19.2711869],
              [-99.2415603, 19.2707388],
              [-99.2412853, 19.2704325],
              [-99.2412581, 19.2702604],
              [-99.2409676, 19.2702607],
              [-99.2408372, 19.2699573],
              [-99.2394146, 19.2653814],
              [-99.2370695, 19.2656388],
              [-99.2345666, 19.2671651],
              [-99.233437, 19.2672735],
              [-99.2314676, 19.2673889],
              [-99.2291524, 19.2662718],
              [-99.2280711, 19.2658637],
              [-99.225651, 19.2660601],
              [-99.2230485, 19.2678567],
              [-99.2213194, 19.2680065],
              [-99.2194572, 19.2680003],
              [-99.2154491, 19.2691689],
              [-99.2126687, 19.2681436],
              [-99.2114394, 19.2676512],
              [-99.2085364, 19.2672398],
              [-99.2068995, 19.2658561],
              [-99.2060381, 19.265225],
              [-99.2052196, 19.2652826],
              [-99.2045836, 19.2657551],
              [-99.2046342, 19.2667947],
              [-99.2052963, 19.2684016],
              [-99.2057454, 19.2711191],
              [-99.2062374, 19.2727632],
              [-99.2081351, 19.2756458],
              [-99.2088904, 19.2765308],
              [-99.2079291, 19.2774968],
              [-99.2004704, 19.2780919],
              [-99.1962389, 19.2794035],
              [-99.1879902, 19.2821481],
              [-99.1870263, 19.2809834],
              [-99.186716, 19.2808365],
              [-99.1857777, 19.2810365],
              [-99.1844608, 19.2805187],
              [-99.1808909, 19.2804364],
              [-99.1796474, 19.2809069],
              [-99.178693, 19.2809294],
              [-99.1782265, 19.2807685],
              [-99.1780819, 19.2818634],
              [-99.1755826, 19.2838025],
              [-99.1738128, 19.2818933],
              [-99.1680197, 19.2793663],
              [-99.1660034, 19.2763126],
              [-99.1636615, 19.2797353],
              [-99.1610406, 19.2837859],
              [-99.1565925, 19.2867425],
              [-99.151559, 19.289982],
              [-99.1482319, 19.2858085],
              [-99.144798, 19.2836608],
              [-99.1421904, 19.2827836],
              [-99.1395829, 19.2822304],
              [-99.1352051, 19.2823251],
              [-99.1282525, 19.2841209],
              [-99.1236458, 19.2856559],
              [-99.1192106, 19.2875148],
              [-99.1061872, 19.2930354],
              [-99.091381, 19.2995876],
              [-99.0873861, 19.304531],
              [-99.0869316, 19.303804],
              [-99.0861128, 19.3034143],
              [-99.0832342, 19.2997034],
              [-99.0785498, 19.294023],
              [-99.0749065, 19.2896792],
              [-99.0720818, 19.2877098],
              [-99.0690211, 19.2848897],
              [-99.0645898, 19.2819533],
              [-99.0602335, 19.2803535],
              [-99.0576424, 19.2795358],
              [-99.0545578, 19.2792852],
              [-99.0505773, 19.2773864],
              [-99.0505044, 19.2853602],
              [-99.0502169, 19.2873895],
              [-99.0400911, 19.2850904],
              [-99.0418674, 19.2973636],
              [-99.0404699, 19.296412],
              [-99.0374142, 19.2960728],
              [-99.0309682, 19.2970702],
              [-99.022035, 19.2985179],
              [-99.0243707, 19.3008013],
              [-99.0257193, 19.3033278],
              [-99.0271293, 19.304695],
              [-99.0302215, 19.3073581],
              [-99.0335282, 19.3096971],
              [-99.0342196, 19.3098391],
              [-99.0414604, 19.3162689],
              [-99.0409765, 19.3170894],
              [-99.0422827, 19.317802],
              [-99.0417971, 19.3186765],
              [-99.041333, 19.3195157],
              [-99.0422597, 19.3199707],
              [-99.041931, 19.320537],
              [-99.0424993, 19.3211397],
              [-99.0414262, 19.3250509],
              [-99.0383365, 19.3257052],
              [-99.036738, 19.3260373],
              [-99.036164, 19.3259402],
              [-99.0316982, 19.3260131],
              [-99.0306213, 19.3257711],
              [-99.0298763, 19.3262525],
              [-99.0295146, 19.3262653],
              [-99.0294785, 19.3262161],
              [-99.0293318, 19.3261763],
              [-99.0287461, 19.3260045],
              [-99.0283803, 19.3260656],
              [-99.0281148, 19.3248983],
              [-99.0275475, 19.3248917],
              [-99.0274301, 19.3247719],
              [-99.0267223, 19.3246665],
              [-99.0252528, 19.3236398],
              [-99.0245548, 19.3245287],
              [-99.0241306, 19.3251958],
              [-99.0238782, 19.3259845],
              [-99.0239841, 19.3267094],
              [-99.0240374, 19.3273285],
              [-99.0242409, 19.3279476],
              [-99.0246694, 19.3281733],
              [-99.025419, 19.3292323],
              [-99.0248648, 19.3297353],
              [-99.024121, 19.3291009],
              [-99.023438, 19.3287483],
              [-99.022796, 19.3286834],
              [-99.0227218, 19.3290305],
              [-99.0218537, 19.3288513],
              [-99.021786, 19.3292042],
              [-99.0195047, 19.328675],
              [-99.0182218, 19.3291852],
              [-99.0184409, 19.3298777],
              [-99.0181924, 19.3310603],
              [-99.0177384, 19.3311171],
              [-99.0174527, 19.3320001],
              [-99.0186058, 19.3358764],
              [-99.0179831, 19.3358015],
              [-99.0178432, 19.335929],
              [-99.0169089, 19.3357184],
              [-99.0168812, 19.3359153],
              [-99.0162635, 19.3373067],
              [-99.0152129, 19.3367218],
              [-99.0141624, 19.3360457],
              [-99.0127051, 19.3348658],
              [-99.0126015, 19.3351278],
              [-99.0133531, 19.3359312],
              [-99.0127904, 19.3368105],
              [-99.0122552, 19.3366861],
              [-99.010809, 19.336417],
              [-99.0107061, 19.3366989],
              [-99.0096636, 19.3367565],
              [-99.0086085, 19.3365679],
              [-99.0085582, 19.3368387],
              [-99.0071172, 19.3365193],
              [-99.0050824, 19.3366886],
              [-99.0046999, 19.3358759],
              [-99.0044655, 19.3358247],
              [-99.0041774, 19.3359963],
              [-99.0033492, 19.3360662],
              [-99.0029588, 19.3363273],
              [-99.0023925, 19.3363233],
              [-99.0021052, 19.3365672],
              [-99.0013245, 19.336801],
              [-99.0011284, 19.3363932],
              [-99.0010825, 19.335879],
              [-99.0010662, 19.33517],
              [-99.0010794, 19.334818],
              [-99.0009666, 19.3346887],
              [-98.998033, 19.3345034],
              [-98.9975157, 19.3344512],
              [-98.9964872, 19.3341416],
              [-98.9953032, 19.3342142],
              [-98.9952996, 19.3348881],
              [-98.9949812, 19.3361514],
              [-98.9951761, 19.3371525],
              [-98.9949571, 19.3377695],
              [-98.9948851, 19.3383514],
              [-98.9948936, 19.3395153],
              [-98.9944491, 19.3394772],
              [-98.993846, 19.3391291],
              [-98.9935874, 19.3391372],
              [-98.9934956, 19.3393438],
              [-98.9900648, 19.3387789],
              [-98.986209, 19.3381016],
              [-98.9859581, 19.3391251],
              [-98.9858407, 19.3399278],
              [-98.9854283, 19.3398448],
              [-98.9852258, 19.3403772],
              [-98.9853788, 19.34046],
              [-98.9848263, 19.3427516],
              [-98.9845984, 19.3431635],
              [-98.9844992, 19.344284],
              [-98.9842793, 19.3445408],
              [-98.9844084, 19.3455479],
              [-98.9837225, 19.3454363],
              [-98.9735118, 19.3467306],
              [-98.9723976, 19.3516091],
              [-98.9730417, 19.3518568],
              [-98.9726559, 19.3525905],
              [-98.9651036, 19.3542601],
              [-98.9578847, 19.3554479],
              [-98.9524196, 19.3612747],
              [-98.9478125, 19.3680731],
              [-98.9380606, 19.3857991],
              [-98.9308215, 19.3986183],
              [-98.9281457, 19.4032467],
              [-98.9251694, 19.4067417],
              [-98.9170279, 19.4169243],
              [-98.9095721, 19.427916],
              [-98.9072011, 19.4352045],
              [-98.9039341, 19.4425315],
              [-98.9011818, 19.449899],
              [-98.9308184, 19.4520541],
              [-98.9531039, 19.4506209],
              [-98.9625152, 19.4520999],
              [-98.9656509, 19.4515916],
              [-98.9687866, 19.4505978],
              [-98.9752294, 19.4481244],
              [-98.9784077, 19.4445403],
              [-98.9794831, 19.4383056],
              [-98.9807727, 19.4360773],
              [-98.9936699, 19.4285788],
              [-98.9953761, 19.4286944],
              [-98.9968678, 19.4292551],
              [-99.0022527, 19.417742],
              [-99.0313503, 19.4251254],
              [-99.0287328, 19.4338465],
              [-99.0481041, 19.4390924],
              [-99.0475233, 19.4400473],
              [-99.0458704, 19.4414504],
              [-99.0444319, 19.4430357],
              [-99.0439216, 19.4443899],
              [-99.0437118, 19.4469986],
              [-99.0385018, 19.4567663],
              [-99.038443, 19.4610714],
              [-99.0348051, 19.4723914],
              [-99.0278111, 19.4856234],
              [-99.0139081, 19.4874774],
              [-99.0140131, 19.5259034],
              [-99.0141395, 19.5653609],
              [-99.0186581, 19.5713995],
              [-99.0195997, 19.5743726],
              [-99.0224462, 19.5777675],
              [-99.021906, 19.5784288],
              [-99.0198066, 19.5795884],
              [-99.0186389, 19.5801631],
              [-99.017894, 19.5806627],
              [-99.0159498, 19.5805182],
              [-99.0124947, 19.5822763],
              [-99.0091574, 19.5842669],
              [-99.0063136, 19.5852162],
              [-99.0031692, 19.585751],
              [-99.0032522, 19.5866378],
              [-99.0005604, 19.5867604],
              [-98.9971224, 19.5864855],
              [-98.9932334, 19.585713],
              [-98.9894625, 19.584996],
              [-98.986405, 19.5843496],
              [-98.983133, 19.5837386],
              [-98.9830976, 19.5838218],
              [-98.9827833, 19.5838039],
              [-98.9825945, 19.5838743],
              [-98.9823564, 19.5844853],
              [-98.9802729, 19.5838478],
              [-98.9795968, 19.5857652],
              [-98.97935, 19.5863735],
              [-98.9792156, 19.5864884],
              [-98.9793763, 19.5867549],
              [-98.979376, 19.5870316],
              [-98.9791915, 19.5873095],
              [-98.9788873, 19.5874383],
              [-98.9783758, 19.5873133],
              [-98.9782076, 19.587744],
              [-98.9782892, 19.5886487],
              [-98.9768483, 19.5882037],
              [-98.9767429, 19.5885885],
              [-98.976084, 19.5885937],
              [-98.9734672, 19.5883666],
              [-98.9660341, 19.5876343],
              [-98.9658418, 19.5877482],
              [-98.9630426, 19.5876701],
              [-98.9626303, 19.5937635],
              [-98.9635185, 19.5937932],
              [-98.9635032, 19.5964097],
              [-98.9636595, 19.5990463],
              [-98.9635342, 19.6006494],
              [-98.9634089, 19.6027276],
              [-98.9618728, 19.6025259],
              [-98.9612339, 19.6057869],
              [-98.9625981, 19.6056365],
              [-98.9628402, 19.607959],
              [-98.9629591, 19.6088868],
              [-98.962502, 19.6111922],
              [-98.9619484, 19.613533],
              [-98.9686789, 19.6147529],
              [-98.9820177, 19.6171233],
              [-98.9816546, 19.6194605],
              [-98.9805665, 19.6224027],
              [-98.9801941, 19.6240456],
              [-98.9799489, 19.6247407],
              [-98.9798423, 19.6254268],
              [-98.9788453, 19.6289526],
              [-98.9788907, 19.6327333],
              [-98.977552, 19.6373427],
              [-98.9816095, 19.6383885],
              [-98.9816378, 19.6389841],
              [-98.9818701, 19.63962],
              [-98.9831716, 19.6406292],
              [-98.9836286, 19.6411521],
              [-98.9845125, 19.6416268],
              [-98.9858619, 19.6427984],
              [-98.9880374, 19.643859],
              [-98.9889577, 19.6443133],
              [-98.9886105, 19.6450532],
              [-98.9880488, 19.6454092],
              [-98.9917318, 19.6487078],
              [-98.9908368, 19.6497623],
              [-98.991876, 19.649874],
              [-98.9916277, 19.6519862],
              [-98.9922944, 19.6536102],
              [-98.9923274, 19.6546596],
              [-98.9921458, 19.6548402],
              [-98.9893901, 19.6617397],
              [-98.9901097, 19.6619564],
              [-98.9901264, 19.6623057],
              [-98.9926639, 19.6632035],
              [-98.9927946, 19.662985],
              [-98.993569, 19.6632413],
              [-98.9944336, 19.6612781],
              [-98.9993108, 19.6625176],
              [-99.0023856, 19.6627974],
              [-99.0022707, 19.6639222],
              [-99.0024348, 19.6647036],
              [-99.0065741, 19.6608061],
              [-99.0113034, 19.663708],
              [-99.0133344, 19.656082],
              [-99.0162935, 19.6572016],
              [-99.0193599, 19.657533],
              [-99.0192077, 19.6523275],
              [-99.0213203, 19.6514727],
              [-99.0228749, 19.6502139],
              [-99.0240827, 19.648018],
              [-99.0247112, 19.6455192],
              [-99.0244835, 19.6440273],
              [-99.023741, 19.6427779],
              [-99.0232238, 19.6415891],
              [-99.0236571, 19.6406157],
              [-99.024702, 19.6393898],
              [-99.0294043, 19.6366694],
              [-99.0413587, 19.6377786],
              [-99.0478402, 19.6384138],
              [-99.0532919, 19.638786],
              [-99.0583737, 19.6493515],
              [-99.0609899, 19.6546646],
              [-99.0635419, 19.6599473],
              [-99.0694343, 19.6720839],
              [-99.0748341, 19.6843214],
              [-99.0841751, 19.7020884],
              [-99.0861812, 19.7045174],
              [-99.0866861, 19.7079564],
              [-99.0916001, 19.7187854],
              [-99.102397, 19.7400578],
              [-99.105137, 19.7454254],
              [-99.1068256, 19.7472182],
              [-99.1099928, 19.7487674],
              [-99.1164435, 19.748913],
              [-99.1241387, 19.7490987],
              [-99.1260647, 19.749663],
              [-99.1275615, 19.7504696],
              [-99.1305553, 19.7503055],
              [-99.143307, 19.7504398],
              [-99.1550716, 19.7503513],
              [-99.1715915, 19.7504388],
              [-99.1755553, 19.7486924],
              [-99.1777168, 19.747199],
              [-99.1773822, 19.7452355],
              [-99.1803295, 19.7401602],
              [-99.1842613, 19.7344085],
              [-99.187313, 19.7277482],
              [-99.1888643, 19.7250565],
              [-99.1928313, 19.7185867],
              [-99.1947441, 19.7152092],
              [-99.195563, 19.7149225],
              [-99.199676, 19.7129366],
              [-99.2019034, 19.7127382],
              [-99.2035416, 19.712438],
              [-99.2050152, 19.7121363],
              [-99.2069111, 19.7121912],
              [-99.210473, 19.7122006],
              [-99.2135301, 19.7123552],
              [-99.2167877, 19.7124686],
              [-99.221923, 19.7126927],
              [-99.2211769, 19.7105595],
              [-99.221085, 19.7086284],
              [-99.2210885, 19.7058961],
              [-99.2211242, 19.7050121],
              [-99.2209314, 19.7043573],
              [-99.222231, 19.7044708],
              [-99.2229668, 19.7044889],
              [-99.223627, 19.7042341],
              [-99.2240094, 19.7033899],
              [-99.223963, 19.7021327],
              [-99.2236042, 19.7012022],
              [-99.2256997, 19.7010028],
              [-99.2259386, 19.7009026],
              [-99.2264337, 19.6999234],
              [-99.2277773, 19.6998671],
              [-99.2282526, 19.6978015],
              [-99.2286561, 19.6957125],
              [-99.2290597, 19.6947146],
              [-99.2294028, 19.6944714],
              [-99.2309689, 19.6942282],
              [-99.2346818, 19.6941355],
              [-99.2353678, 19.6939154],
              [-99.2363548, 19.6937448],
              [-99.2373894, 19.6938216],
              [-99.238885, 19.6933431],
              [-99.2437627, 19.6882686],
              [-99.2444654, 19.6879251],
              [-99.2451593, 19.6866383],
              [-99.2459269, 19.6862125],
              [-99.2471515, 19.684418],
              [-99.2478806, 19.6829161],
              [-99.2465701, 19.6818207],
              [-99.2456913, 19.6811737],
              [-99.2440352, 19.6803454],
              [-99.2432655, 19.6804112],
              [-99.2424958, 19.680376],
              [-99.2415825, 19.680232],
              [-99.240916, 19.6803104],
              [-99.2395468, 19.6790401],
              [-99.2388569, 19.6782736],
              [-99.2383171, 19.6777899],
              [-99.2376977, 19.6770779],
              [-99.2369281, 19.6759616],
              [-99.2362536, 19.6750499],
              [-99.2348174, 19.6743504],
              [-99.2336937, 19.6735069],
              [-99.2343446, 19.6735457],
              [-99.2356177, 19.6726853],
              [-99.2373915, 19.6721568],
              [-99.2386754, 19.671342],
              [-99.241479, 19.6699348],
              [-99.2482235, 19.6660291],
              [-99.2470458, 19.6633338],
              [-99.2496931, 19.6611762],
              [-99.2511517, 19.6603753],
              [-99.2527175, 19.6592512],
              [-99.2540144, 19.657983],
              [-99.2580525, 19.6541103],
              [-99.2615465, 19.6528975],
              [-99.2612564, 19.6537525],
              [-99.2614061, 19.65388],
              [-99.261373, 19.6542361],
              [-99.2615706, 19.6544456],
              [-99.2620042, 19.6547158],
              [-99.2638998, 19.6546022],
              [-99.264509, 19.6545157],
              [-99.2649769, 19.6544326],
              [-99.2651177, 19.6545213],
              [-99.2653924, 19.654231],
              [-99.2655291, 19.6544386],
              [-99.2655101, 19.6560659],
              [-99.2665266, 19.6557011],
              [-99.2675859, 19.6554677],
              [-99.2692756, 19.6548393],
              [-99.2698092, 19.6551378],
              [-99.2700637, 19.6547999],
              [-99.2727347, 19.6528408],
              [-99.2737858, 19.652],
              [-99.2750246, 19.6508614],
              [-99.2753673, 19.6503016],
              [-99.27679, 19.6492359],
              [-99.2783737, 19.6482459],
              [-99.2797171, 19.6468824],
              [-99.2800746, 19.646784],
              [-99.2818054, 19.6455539],
              [-99.2831402, 19.6451966],
              [-99.2862452, 19.6449907],
              [-99.2882603, 19.644094],
              [-99.2911423, 19.6426139],
              [-99.2935952, 19.6421441],
              [-99.2964128, 19.6426348],
              [-99.2989355, 19.6430396],
              [-99.3003437, 19.6429297],
              [-99.301721, 19.6417089],
              [-99.3033108, 19.6404948],
              [-99.3040637, 19.6395737],
              [-99.3058057, 19.6380244],
              [-99.3071186, 19.6360306],
              [-99.3086229, 19.6346619],
              [-99.3101594, 19.633758],
              [-99.310852, 19.6325165],
              [-99.310815, 19.6317902],
              [-99.3114218, 19.6308821],
              [-99.3121895, 19.6293476],
              [-99.3126317, 19.6282727],
              [-99.3128646, 19.628082],
              [-99.3132747, 19.6274517],
              [-99.313631, 19.6267608],
              [-99.3141873, 19.6263923],
              [-99.3157502, 19.6258826],
              [-99.3168563, 19.625749],
              [-99.3179516, 19.6256963],
              [-99.3183182, 19.6264195],
              [-99.3191409, 19.6268446],
              [-99.320475, 19.6271592],
              [-99.3218449, 19.6272912],
              [-99.3223242, 19.6274232],
              [-99.3235639, 19.6260976],
              [-99.3250397, 19.6250145],
              [-99.3301846, 19.6242597],
              [-99.3323792, 19.6232674],
              [-99.3303144, 19.6224874],
              [-99.3274527, 19.6217008],
              [-99.3264203, 19.6214143],
              [-99.326273, 19.621133],
              [-99.3263603, 19.6209241],
              [-99.3267802, 19.620503],
              [-99.3268829, 19.620156],
              [-99.3269105, 19.6197483],
              [-99.326643, 19.6194695],
              [-99.3263219, 19.6192312],
              [-99.3253646, 19.6187932],
              [-99.3244764, 19.6185478],
              [-99.3238295, 19.6185096],
              [-99.3230206, 19.6187355],
              [-99.322067, 19.6188351],
              [-99.3207415, 19.619371],
              [-99.3194489, 19.6195929],
              [-99.3181562, 19.6196734],
              [-99.3165975, 19.6197267],
              [-99.3149974, 19.6197857],
              [-99.3142235, 19.6198246],
              [-99.3130631, 19.6198357],
              [-99.3115275, 19.6191645],
              [-99.3105964, 19.6188755],
              [-99.3081525, 19.6184146],
              [-99.3074039, 19.6186258],
              [-99.3052228, 19.6177102],
              [-99.3037285, 19.617934],
              [-99.3023136, 19.6171817],
              [-99.3015155, 19.617137],
              [-99.3003419, 19.6165313],
              [-99.299871, 19.614435],
              [-99.2998841, 19.6129812],
              [-99.2986475, 19.6111938],
              [-99.2963057, 19.6092952],
              [-99.295348, 19.6081444],
              [-99.2941543, 19.607216],
              [-99.2925799, 19.6066717],
              [-99.2919857, 19.6062631],
              [-99.2916706, 19.6051267],
              [-99.2899608, 19.602813],
              [-99.2895062, 19.601803],
              [-99.2872279, 19.5998278],
              [-99.2865537, 19.599718],
              [-99.2848495, 19.6001036],
              [-99.2844373, 19.5994524],
              [-99.2847945, 19.5986669],
              [-99.2840573, 19.5967291],
              [-99.2791884, 19.5924653],
            ],
            [
              [-99.0580786, 19.3328269],
              [-99.0643224, 19.3339742],
              [-99.0666288, 19.3303362],
              [-99.0749434, 19.3316382],
              [-99.0758461, 19.3185513],
              [-99.0709125, 19.3144553],
              [-99.0699577, 19.3160649],
              [-99.0607632, 19.3154064],
              [-99.0515997, 19.3259962],
              [-99.0580786, 19.3328269],
            ],
            [
              [-99.2889388, 19.5862333],
              [-99.2865765, 19.5863276],
              [-99.2874015, 19.5850465],
              [-99.2889388, 19.5862333],
            ],
            [
              [-99.0175285, 19.3533362],
              [-99.0151157, 19.3521995],
              [-99.0132823, 19.3512451],
              [-99.0082732, 19.3501692],
              [-99.0050557, 19.3497107],
              [-99.004499, 19.3514387],
              [-99.0034713, 19.3570812],
              [-99.0023486, 19.3617811],
              [-99.0044874, 19.3633634],
              [-99.0108442, 19.3655881],
              [-99.0150554, 19.3666385],
              [-99.0159389, 19.3644245],
              [-99.0175948, 19.3627774],
              [-99.0232458, 19.3564567],
              [-99.0175285, 19.3533362],
            ],
            [
              [-99.164427, 19.5478654],
              [-99.1633571, 19.5320124],
              [-99.1551661, 19.5231944],
              [-99.1442291, 19.5176124],
              [-99.1288292, 19.5176514],
              [-99.114116, 19.5217334],
              [-99.108224, 19.5357274],
              [-99.109569, 19.5461214],
              [-99.112631, 19.5561924],
              [-99.1289152, 19.5833704],
              [-99.1565681, 19.5588654],
              [-99.164427, 19.5478654],
            ],
          ],
        },
      },
    ],
  }
};
