<template>
  <Layout>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>
          {{ $t('safety.titles.header') }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="pb-4">
      <b-col>
        <label for="live">
          <input type="checkbox" id="live" name="live" v-model="live">
          Live
        </label>
      </b-col>
      <b-col v-if="live">
        <label for="detect">
          Mode
        </label>
        <select name="detect" id="detect" v-model="detect">
          <option value="inside">inside</option>
          <option value="outside">outside</option>
        </select>
      </b-col>
    </b-row>
    <div>
      <b-card body-class="p-0">
        <b-card-body class="p-0">
          <Map
            class="maps"
            :center="center"
            :zoom="10"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false
            }"
            ref="mapRef"
            @dragend="deferFetchItems"
            @zoom_changed="deferFetchItems"
          >
            <GmapCluster>
              <MapMarker
                v-for="(item, vin) in fleet"
                :key="vin"
                :position="item"
                :title="vin"
                :clickable="true"
                :icon="carIcon(item.rotation)"
                @click="center=item"
              />
            </GmapCluster>
          </Map>
        </b-card-body>
      </b-card>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import { Map, Marker as MapMarker } from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/src/components/cluster';
import { i18next } from '@src/translate/i18n';
import { cdmx } from '@src/maps/cdmx';

const cities = { 
  cdmx
}

const carIcon = (rotation) => ({
  path: "M62.1,36.5c-0.9-1.2-3.6-1.5-3.6-1.5c0.1-3.5,0.5-6.9,0.7-8.2c1.9-7.3-1.7-11.8-1.7-11.8c-4.8-4.8-9.1-5-12.5-5   c-3.4,0-7.8,0.3-12.5,5c0,0-3.6,4.5-1.7,11.8c0.2,1.2,0.5,4.6,0.7,8.2c0,0-2.7,0.3-3.6,1.5c-0.9,1.2-0.9,1.9,0,1.9   c0.9,0,2.9-2.3,3.6-2.3V35c0,1,0.1,2,0.1,3c0,4.4,0,33.7,0,33.7s-0.3,6.1,5,7.8c1.2,0,4.6,0.4,8.4,0.5c3.8-0.1,7.3-0.5,8.4-0.5   c5.3-1.7,5-7.8,5-7.8s0-29.3,0-33.7c0-1,0-2,0.1-3v1.2c0.7,0,2.7,2.3,3.6,2.3C63,38.5,63,37.7,62.1,36.5z M34.7,66.5   c-0.3,3.3-2.3,4.1-2.3,4.1V37.4c0.8,1.2,2.3,6.8,2.3,6.8S34.9,63.2,34.7,66.5z M54.8,75.2c0,0-4.2,2.3-9.8,2.2   c-5.6,0.1-9.8-2.2-9.8-2.2v-2.8c4.9,2.2,9.8,2.2,9.8,2.2s4.9,0,9.8-2.2V75.2z M35.2,41.1l-1.7-10.2c0,0,4.5-3.2,11.5-3.2   s11.5,3.2,11.5,3.2l-1.7,10.2C51.4,39.2,38.6,39.2,35.2,41.1z M57.7,70.6c0,0-2.1-0.8-2.3-4.1c-0.3-3.3,0-22.4,0-22.4   s1.5-5.6,2.3-6.8V70.6z",
  fillColor: "#FF0000",
  fillOpacity: 1,
  scaledSize: new google.maps.Size(40, 40),
  origin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(80, 80),
  strokeWeight: 1,
  scale: .5,
  rotation: rotation
});

export default {
  name: 'SafetyV2',
  page: {
    title: i18next.t('safety.titles.header'),
  },
  components: {
    Layout,
    Map,
    MapMarker,
    GmapCluster
  },
  data: () => ({
    fleet: {},
    center: {
      lat: cdmx.center[0],
      lng: cdmx.center[1]
    },
    carIcon,
    live: false,
    detect: 'outside',
    connection: null
  }),
  async mounted() {
    const map = await this.$refs.mapRef.$mapPromise
    await map.data.addGeoJson(cdmx.geojson)

    await this.fetchItems()
  },
  beforeDestroy() {
    if(this.connection) {
      this.connection.close()
    }
  },
  methods: {
    async fetchItems() {
      if(this.connection) {
        this.connection.close()
      }
      const map = await this.$refs.mapRef.$mapPromise
      const bounds = await map.getBounds()
      if(!bounds) {
        setTimeout(() => {
          this.fetchItems()
        }, 200)
        return
      }

      const response = await fetch(`https://${process.env.VUE_APP_FLEET_TRACKING_GEODB_HOST}`, {
        method: 'POST',
        body: `WITHIN fleet limit 10000 BOUNDS ${bounds.zb.h} ${bounds.Ua.h} ${bounds.zb.j} ${bounds.Ua.j}`
      })

      

      const data = await response.json()

      this.fleet = {}
      const items = {}

      const fields = Object.fromEntries(Object.entries(data.fields).map(a => a.reverse()))

      data.objects.map((item) => {
        items[item.id] = {
          lat: item.object.coordinates[1],
          lng: item.object.coordinates[0],
          rotation: item.fields[fields['heading']] || 90
        }
      })

      this.fleet = items
    },
    deferFetchItems() {
      if(!this.live){
        this.fetchItems()
      }
    },
    liveItems() {
      if(this.connection) {
        this.connection.close()
      }
      this.fleet = {}
      this.connection = new WebSocket(`wss://${process.env.VUE_APP_FLEET_TRACKING_GEODB_HOST}/WITHIN fleet FENCE DETECT ${this.detect} OBJECT ${JSON.stringify(cdmx.geojson)}`)
      this.connection.onmessage = (message) => {
        if (!message.data.startsWith('{')) {
          return;
        }
        const data = JSON.parse(message.data)
        this.fleet = {
          ...this.fleet,
          [data.id]: {
            lat: data.object.coordinates[1],
            lng: data.object.coordinates[0],
            rotation: data.fields.heading || 90
          }
        }
      }
    },
    async setCenter(city) {
      this.center.lat = cities[city].center[0]
      this.center.lng = cities[city].center[1]
      this.fetchItems()
    }
  },
  watch: {
    live(live) {
      if(live) {
        this.liveItems()
      } else {
        this.fetchItems()
      }
    },
    detect() {
      if(this.live) {
        this.liveItems()
      } else {
        this.fetchItems()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .maps {
    border-radius: 0.25rem;
    min-height: 600px;
    height: 100%;
    height: 100%
  }
</style>
