var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [
              _vm._v(" " + _vm._s(_vm.$t("safety.titles.header")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", [
            _c("label", { attrs: { for: "live" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.live,
                    expression: "live",
                  },
                ],
                attrs: { type: "checkbox", id: "live", name: "live" },
                domProps: {
                  checked: Array.isArray(_vm.live)
                    ? _vm._i(_vm.live, null) > -1
                    : _vm.live,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.live,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.live = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.live = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.live = $$c
                    }
                  },
                },
              }),
              _vm._v(" Live "),
            ]),
          ]),
          _vm.live
            ? _c("b-col", [
                _c("label", { attrs: { for: "detect" } }, [_vm._v(" Mode ")]),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.detect,
                        expression: "detect",
                      },
                    ],
                    attrs: { name: "detect", id: "detect" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.detect = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "inside" } }, [
                      _vm._v("inside"),
                    ]),
                    _c("option", { attrs: { value: "outside" } }, [
                      _vm._v("outside"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-card",
            { attrs: { "body-class": "p-0" } },
            [
              _c(
                "b-card-body",
                { staticClass: "p-0" },
                [
                  _c(
                    "Map",
                    {
                      ref: "mapRef",
                      staticClass: "maps",
                      attrs: {
                        center: _vm.center,
                        zoom: 10,
                        options: {
                          zoomControl: true,
                          mapTypeControl: false,
                          scaleControl: false,
                          streetViewControl: false,
                          rotateControl: false,
                          fullscreenControl: true,
                          disableDefaultUi: false,
                        },
                      },
                      on: {
                        dragend: _vm.deferFetchItems,
                        zoom_changed: _vm.deferFetchItems,
                      },
                    },
                    [
                      _c(
                        "GmapCluster",
                        _vm._l(_vm.fleet, function (item, vin) {
                          return _c("MapMarker", {
                            key: vin,
                            attrs: {
                              position: item,
                              title: vin,
                              clickable: true,
                              icon: _vm.carIcon(item.rotation),
                            },
                            on: {
                              click: function ($event) {
                                _vm.center = item
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }